import {setPageSection} from './ab_ga_events';
import Typed from 'typed.js';

var nav_height, size;
var m0, m1, m_app_phone, m_typed, m_app_info, m_contact, dinamic_slideshow, dsimg_height, m_app_stats, m_download;
var phone_slideshow, typed, firstRound = true;
var debug = false;
var url;
var domain = window.location.hostname;
let currentSection = '';

let windowHeight = $(window).height();
let windowWidth = $(window).width();
if(windowWidth < 680){
    size = 4;
}else if(windowWidth >= 680 && windowWidth < 992){
    size = 6;
}else {size = 12;}

$(".dinamic-slideshow, .najveckrat-izposojene, .nove-knjige, .vse-knjige").attr({ 'data-items': size });

$( document ).ready(function() {

    $(".navbar-nav").find("a").click(function(e) {
        let real_link = false
        let collapse = 0

        if($(this).attr('id') === "bralci"){real_link = true}
        if($(this).attr('id') === "zalozniki"){real_link = true}
        if($(this).attr('id') === "unovcenje"){real_link = true}
        if($(this).attr('id') === "book-store"){real_link = true}
        if($(this).attr('id') === 'gPlay'){real_link = true}
        if($(this).attr('id') === 'aStore'){real_link = true}
        if($(this).hasClass('real-link')){real_link = true}

        if(!real_link){
            if($(".navbar-collapse.show").length)
                collapse = parseInt($(".navbar-collapse.show").outerHeight())
            e.preventDefault();
            $("#navbarNav").collapse('hide');
            $(".navbar-toggler-icon.x, .navbar-toggler-icon.open").addClass('d-none')
            $(".navbar-toggler-icon.open").removeClass('d-none')
            let section = $(this).attr("href");
            //console.log(section);

            $("html, body").animate({
                scrollTop: $(section).offset().top - $(".fixed-top").outerHeight() + collapse + 1
            },1000, 'swing');
        }
    });

    $(window).on('scroll', function() {
        let scrollPosition = $(this).scrollTop();
        let scrollPositionBottom = scrollPosition+windowHeight-nav_height;
        if(scrollPositionBottom>m_typed){
            typed.start();
        }else{
            typed.stop();
        }

        if (scrollPosition > m1 && scrollPosition <= m_app_phone) { //First section
            if (currentSection !== '') {
                currentSection = '';
                setPageSection(currentSection);
            }
            if($(window).width() > 576){
                typed.stop();
            }

            $('.navbar-nav .nav-item').removeClass('active');
            $('.first').addClass('active');
        }else if (scrollPosition > m_app_phone && scrollPosition <= m_contact ) {
            typed.start();
            $('.navbar-nav .nav-item').removeClass('active');
            $('.second').addClass('active');
        }else if (scrollPosition > m_contact ) {
            if (currentSection !== 'contact') {
                currentSection = 'contact';
                setPageSection(currentSection);
            }
            typed.stop();
            $('.navbar-nav .nav-item').removeClass('active');
            $('.third').addClass('active');
        }

        //Hide/Show mobile download bar
        if (scrollPosition < m0 ) {
            $(".g-play-app-store").removeClass("d-none");
            $(".g-play-app-store2").addClass("d-none");
        }else {
            $(".g-play-app-store").addClass("d-none");
            $(".g-play-app-store2").removeClass("d-none");
        }

        //current section checks
        if (scrollPosition > m_app_phone && scrollPosition <= m_app_stats ) {
            if (currentSection !== 'app_phone') {
                currentSection = 'app_phone';
                setPageSection(currentSection);
            }
        }else if(scrollPosition > m_app_stats && scrollPosition <= m_app_info) {
            if (currentSection !== 'app_stats') {
                currentSection = 'app_stats';
                setPageSection(currentSection);
            }
        }else if(scrollPosition > m_app_info && scrollPosition <= m_download){
            if (currentSection !== 'app_info') {
                currentSection = 'app_info';
                setPageSection(currentSection);
            }
        }else if(scrollPosition > m_download && scrollPosition <= m_contact){
            if (currentSection !== 'download') {
                currentSection = 'download';
                setPageSection(currentSection);
            }
        }
    });

    $('.navbar-toggler-icon.open').click(function() {
        $(".navbar-toggler-icon.x, .navbar-toggler-icon.open").addClass('d-none')
        $(".navbar-toggler-icon.x").removeClass('d-none')
    });

    $('.navbar-toggler-icon.x').click(function() {
        $(".navbar-toggler-icon.x, .navbar-toggler-icon.open").addClass('d-none')
        $(".navbar-toggler-icon.open").removeClass('d-none')
    })

    $('.arrow').click(function() {
        $("html, body").animate({
            scrollTop: $("#app_phone").offset().top - $(".navbar").outerHeight() + 1
        },1000, 'swing');
    })
});

function set_width_but(){
    //button_holder_hp
    if(windowWidth > 992) {
        let wtext = $('.measure_wt').width();
        $('.button_holder_hp').css('max-width', wtext);
    }
}

$(window).on('load', function(){
    dinamic_slideshow = Math.round($(".dinamic-slideshow .your-class .row .col-6").height())
    dsimg_height = Math.round($(".dinamic-slideshow .your-class .row .col-6 .knjiga img").height())
    $(".your-class").css({'min-height': dinamic_slideshow * 2});  //should be 365

    let rearrange_height = function() {
        if($("#domov").length > 0) {
            let navHeight = $('#navBar').height();
            let windowHeight = $(window).height();
            let height = $(window).height() - (parseInt($("#domov").css("padding-top").slice(0, -2))) - $('.arrow img').height();

            $(".first-half, .second-half, .cta-bg-50").css({'height': height+100});
            $(".cta-bg-50.bg-messy").css({'top': height});

            if ($(window).width() < 1200) {
                $(".second-half").css({'height': parseInt(height * 0.3)});
            } else {
                //$(".second-half").css({'height': parseInt(height * 0.8)});
            }

            let phoneHeight = Math.round(height * 0.604); //LG = height*0.658
            if ($(window).width() > 1200) {
                phoneHeight = Math.round(height * 0.658);
            }
            let phoneWidth = phoneHeight * 0.55;
            let phonePaddingTop = phoneHeight * 0.02;
            let phonePaddingBottom = phoneHeight * 0.02;
            let phonePaddingHorizontal = phoneWidth * 0.019;

            $('.phone_img').css({'max-height': phoneHeight - (phonePaddingTop + phonePaddingBottom), 'height': 'auto'});

            $('.phone_screen img').css({
                'display': '',
                'max-height': phoneHeight - (phonePaddingTop + phonePaddingBottom)
            });
            $('.phone_slideshow').css({
                'padding-top': phonePaddingTop,
                'padding-bottom': phonePaddingBottom,
                'padding-left': phonePaddingHorizontal,
                'padding-right': phonePaddingHorizontal,
                'max-width': phoneHeight * 0.5
            });

            let phone = $('.sticky');
            let top = Math.round(windowHeight / 2 - phone.height() / 2 + navHeight);
            phone.css({'top': top});

            let paddingBottom = ($('.second-half').height() - phone.height()) / 2;
            $('#phone-app-display-container').css({'padding-bottom': paddingBottom, 'max-width': phoneWidth});
        }
    };

    let make_sticky = function() {
        let elements = $('.sticky');
        Stickyfill.add(elements);
    };

    make_sticky();
    phone_slideshow = $('.phone_slideshow');
    phone_slideshow.slick({
        dots: false,
        infinite: true,
        speed: 500,
        //fade: true,
        cssEase: 'linear',
        arrows:'false',
        autoplay:false,
        autoplaySpeed:4200,
        draggable:false,
        swipe:false,
        touchMove:false
    });
    Stickyfill.refreshAll();

    rearrange_height();

    let getParams = window.location.search.substr(1).split('&');
    if(getParams.length>0 && getParams[0].startsWith('download')){
        //scroll to download section

        /*console.log($('#download').offset().top);
        console.log($(".fixed-top").outerHeight());*/

        $("html, body").animate({
            scrollTop: $('#download').offset().top + 1
        },1000, 'swing');
    }

    var reset_typed = function() {
        typed.reset();
        phone_slideshow.slick('slickGoTo', 0);
        firstRound = true;
        //console.log('reset_typed()');
    };

    var slickNext = function(pos) {
        if(firstRound){
            if(pos === 0) {}
            else {phone_slideshow.slick('slickNext');}
            firstRound = false
        }else{
            phone_slideshow.slick('slickNext');
        }
    };

    let animationStringsHtml = [];
    animation_texts.forEach(function (item,index) {
       animationStringsHtml.push(item[0]+"<br/><span class='text-darkblue'>"+item[1]+"</span>");
    });
    //console.log(animationStringsHtml);

    let options = {
        strings: animationStringsHtml,
        typeSpeed:40,
        backSpeed:20,
        backDelay:3500,
        loop: true,
        contentType:'html',
        smartBackspace:false,
        preStringTyped: function(pos, self) { slickNext(pos) },
        onStringTyped: function(pos, self) {},
        onStop: function(pos, self) { reset_typed() },
    };

    typed = new Typed(".typed", options);
    typed.stop();

    nav_height = $(".fixed-top").outerHeight();
    m1 = parseInt($("#cta").offset().top - nav_height);
    m_app_phone = parseInt($("#app_phone").offset().top - nav_height);
    m_app_stats = parseInt($("#app_stats").offset().top - nav_height);
    m_app_info = parseInt($("#aplikacija").offset().top - nav_height);
    // console.log($("#download").offset().top);
    m_download = parseInt($("#download").offset().top - nav_height);
    m_contact = parseInt($("#kontakt").offset().top - nav_height);
    m0 = parseInt($("#app_phone").offset().top - nav_height);
    let typedContainer = $('#typed_container');
    m_typed = parseInt(typedContainer.offset().top)-nav_height+typedContainer.height();

    url = window.location.href;
    if(url.indexOf("?") > -1) {
        let section = url.split("?").pop();
        if(section.indexOf('&')){
            section = section.split('&').at(0);
        }
        $("html, body").animate({
            scrollTop: $("#" + section).offset().top - nav_height + 2
        },1000, 'swing');
    }

    set_width_but();
});
